<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col lg="6">
        <iq-card class="p-4"> <!--top-box register-content-box-->
          <b-row class="justify-content-center">
<!--            <b-col lg="6">
              <div>
                <div class="d-flex flex-column">
                  <span class="title-colored-part font-size-16">{{ $t('register.inst') }}</span>
                  <p class="text-initial text-white font-size-14">{{ $t('register.data') }}</p>
                </div>
                <div class="instructions">
                  <ul class="m-0 p-0">
                    <li class="list_instruction">
                      <span class="number_instruction">1</span>
                      <p class="m-0 font-size-18" v-html="$t('register.dual')"></p>
                    </li>
                    <li class="list_instruction">
                      <span class="number_instruction">2</span>
                      <p class="m-0 font-size-18" v-html="$t('register.fullTitle')"></p>
                    </li>
                    <li class="list_instruction">
                      <span class="number_instruction">3</span>
                      <p class="m-0 font-size-18">{{ $t('register.address') }}</p>
                    </li>
                    <li class="list_instruction">
                      <span class="number_instruction">4</span>
                      <p class="m-0 font-size-18" v-html="$t('register.numbers')"></p>
                    </li>
                  </ul>
                </div>
              </div>
            </b-col>-->
            <b-col lg="9" cols="11">
              <div class="text-center">
                <curva-title :title="$t('login.create')" />
                <validationObserver v-slot="{ handleSubmit }">
                  <b-form @submit.prevent="handleSubmit(register)" class="pt-4 text-initial">
                    <!--position-relative registration_form-->
                    <b-row>
                      <b-col lg="12" class="mb-2">
                        <input-form
                          v-model="user_data.fullname"
                          :placeholder="$t('forms.name')"
                          :validate="'required'"
                          name="name"
                        />
                      </b-col>
                      <b-col lg="12" class="mb-2">
                        <input-form
                          v-model="user_data.email"
                          :placeholder="$t('forms.email')"
                          :validate="'required|email'"
                          name="email"
                        />
                      </b-col>
                      <b-col lg="12" class="mb-2">
                        <input-form
                          v-model="user_data.mobile"
                          :placeholder="$t('forms.phone')"
                          :validate="'required|numeric|digits:11'"
                          name="mobile number"
                        />
                      </b-col>
                      <b-col lg="6" class="mb-2">
                        <input-form
                          type="date"
                          v-model="user_data.birthday"
                          placeholder="Date Of Birth"
                          :validate="`required`"
                          name="Date of birth"
                          :max="new Date().toLocaleDateString('en-ca')"
                        />
                      </b-col>
                      <b-col lg="6" class="mb-2">
                        <main-select
                          :placeholder="$t('forms.gender')"
                          v-model="user_data.gender"
                          :options="genderOptions" label="value" :reduce="data => data.key"
                          :validate="'required'"
                          :name="`Gender`" :searchable="false"
                          custom-class="curva-main-select"
                        ></main-select>
                      </b-col>
                      <b-col lg="12">
                        <validation-provider
                          #default="{ errors }"
                          name="Password"
                          rules="required"
                          ref="password"
                        >
                          <div>
                            <input
                              type="password"
                              :class="[
                                'form-control mb-3',
                                { 'is-invalid': errors.length > 0 },
                              ]"
                              v-model="user_data.password"
                              :placeholder="$t('forms.password')"
                              name="password"
                            />
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col lg="12" class="mb-3">
                        <validation-provider
                          #default="{ errors }"
                          name="Repeat Password"
                          rules="required"
                        >
                          <div>
                            <input
                              type="password"
                              :class="[
                                'form-control',
                                { 'is-invalid': errors.length > 0 },
                              ]"
                              v-model="user_data.password_confirmation"
                              :placeholder="$t('forms.repeat')"
                              name="Repeat Password"
                            />
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col lg="12" class="mb-3">
                        <b-form-checkbox class="custom-checkbox-color-checked" plain v-model="terms"
                                         color="warning" name="color" >
                          <span class="text-gray font-size-16 mx-2">{{ $t('cart.terms1') }}
                          <router-link :to="{ name: 'terms' }" class="text-warning">{{ $t('cart.terms2') }}</router-link></span>
                        </b-form-checkbox>
                      </b-col>
  <!--                    <b-col lg="12" class="my-3">
                        <div class="d-flex flex-wrap">
                          <span class="text-white mr-2">{{ $t('cart.terms1') }}</span>
                          <router-link :to="{ name: 'terms' }">
                            <span class="text-warning">{{ $t('cart.terms2') }}</span></router-link>
                        </div>
                      </b-col>-->
                      <b-col lg="12">
                        <b-button
                          variant="warning"
                          type="submit"
                          class="w-100"
                          v-if="!loginLoading"
                        >
                          <span class="font-size-16">{{ $t('register.register') }}</span>
                        </b-button>
                        <b-button variant="warning" class="w-100" v-else>
                          <spinner-loading :text="$t('login.checking')" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validationObserver>
                <b-row>
                  <b-col lg="12" class="mt-4">
                    <div class="d-flex">
                      <span class="text-white mr-2">{{ $t('register.already') }}</span>
                      <router-link :to="{ name: 'login' }">
                        <span class="text-warning"> {{ $t('login.login') }}</span></router-link>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import authServices from '../auth.services'
export default {
  data () {
    return {
      user_data: {
        fullname: '',
        email: '',
        mobile: '',
        birthday: '',
        gender: '',
        password: '',
        password_confirmation: ''
      },
      loginLoading: '',
      genderOptions: [
        {
          key: 'male',
          value: this.$t('male')
        },
        {
          key: 'female',
          value: this.$t('female')
        }
      ],
      terms: false
    }
  },
  methods: {
    register () {
      if (!this.terms) {
        core.showSnackbar('error', this.$t('forms.terms'))
      } else {
        this.loginLoading = true
        authServices
          .registration(this.user_data)
          .then((res) => {
            core.showSnackbar(res.data.message)
            // this.$router.push({ name: 'registration' })
            localStorage.setItem('userInfo', JSON.stringify(res.data.data))
            localStorage.setItem('userToken', res.data.data.access_token)
            this.$router.push('/')
            this.$store.commit('loginStatus', true)
          })
          .finally(() => {
            this.loginLoading = false
          })
      }
    }
  },
  created () {
    if (localStorage.getItem('userToken')) {
      if (this.$route.name === 'index') {
        window.location.reload()
      } else {
        this.$router.push({ name: 'home' }).then(() => { window.location.reload() })
      }
    }
  },
  mounted () {
    core.index()
  }
}
</script>
